<template>
    <div class="row align-items-center">
      <div class="col-10">
        <div class="input-group input-group-sm" style="min-width:100px;">
          <input
            type="text"
            class="form-control"
            v-bind:value="Number(value.toFixed(2))"
            v-on:input="onInput"
            ref="numberInput"
          />
          <div class="input-group-append" v-if="row.currency_code.symbol">
            <span class="input-group-text">
              {{ row.currency_code.symbol }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "TableEditPrice",
    data() {
      return {
        data: {
          current: this.value,
        },
      };
    },
    props: {
      value: {
        default: 1,
      },
      row: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      componentId() {
        return (
          "wetable-content-row-cell-price-" +
          helper.getRandomInteger(1, 999999)
        );
      },
    },
    methods: {
      onInput(e) {
        if (e && e.target.value) {
          this.data.current = this.filterInput(e.target.value);
          this.triggerEvent();
        }
      },
      triggerEvent() {
        let data = {
          row: this.row,
          value: this.data.current,
        };
        this.$emit("input", this.data.current);
        this.$parent.$parent.$parent.$emit("price-change", data);
      },
      filterInput(value) {
        const filtered = helper.numberInput(value);
        this.$refs.numberInput.value = filtered;
        return filtered;
      },
    },
  };
  </script>
  